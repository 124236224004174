import request from 'superagent'
import { addToast } from 'src/toasts'
import { lockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

export default () => {
  // set dropdown values from local storage
  const usersIndexSelectRole = localStorage.getItem("usersIndexSelectRole")
  if (usersIndexSelectRole) {
    $("#select-role").val(usersIndexSelectRole)
  }

	// datatable init
  let usersDatatable = $('#users-datatable').DataTable({
    "language": {
      url: "/datatable_i18n"
    },
    "pageLength": (localStorage.getItem('usersIndexPageLength') || 25),
    "processing": true,
    "responsive": true,
    "serverSide": true,
    "ajax": {
      "url": $('#users-datatable').data('source'),
      "data": function (d) {
        d.role = $('#select-role').val()
      }
    },
    "columns": [
      {"data": "first_name"},
      {"data": "last_name"},
      {"data": "phone"},
      {"data": "email"},
      {"data": "role"},
      {"data": "title"},
      {"data": "company"},
      {"data": "aux_inc_locations"},
      {"data": "sign_in_count"},
      {"data": "current_sign_in_at"}
    ],
    initComplete(settings, json) {
      $("#datatable-actions").prependTo('#users-datatable_wrapper')
      $("#users-datatable_length").prependTo('#datatable-actions')
      $("#users-datatable_length").addClass('me-3')
      $("#users-datatable_filter input").removeClass('form-control-sm')
      $("#users-datatable_filter input").addClass('datatable-search')
      $('#users-datatable_filter input').attr("type", 'text')
      $("#users-datatable_filter input").detach().prependTo('#search-div')
      $('#users-datatable_wrapper .row:first').remove()
      $("#datatable-actions").removeClass('d-none')

      $('.datatable-search').on('input', function() {
        if ($('#search-div input').val()) {
          $('.clear-button').removeClass('d-none')
        } else {
          $('.clear-button').addClass('d-none')
        }
      })
  	}
  })

  $('#content').on('change', "[name='users-datatable_length']", function() {
    localStorage.setItem('usersIndexPageLength', $(this).val())
  })

  $('#content').on('change', '#select-role', function() {
    localStorage.setItem('usersIndexSelectRole', $(this).val(), 365)
    usersDatatable.ajax.reload()
  })

  $('.clear-button').on('click', function() {
    $('.clear-button').addClass('d-none')
    usersDatatable.search('').draw()
  })

  $('#content').on('click', '#add_user', function() {
    lockButton('#add_user')
    offcanvasRight.show()
    $.getScript('/users/new')
  })

  $('#mainModal').on('click', '#confirmDelete', function(event) {
    $(this).blur()
    if ($("#basic-information").find('form').attr('action').includes('users')) {
      lockButton('#confirmDelete')

      let params = ''
      if ($('#transferEvents').length) {
        params = '?new_id=' + $('#transferEvents').val()
      }
      
      request.delete($("#offcanvasRight .tab-pane.active form").attr('action') + params)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          addToast(res.body.success, 'notice')
          usersDatatable.ajax.reload()
          mainModal.hide()
          $('#offcanvasRight .btn-close').click()
        })
        .catch(err => {
          handleErrors(err, '#confirmDelete')
        })
    }
  })
}
