import { customerFunctions } from 'packs/customers/functions'

export default () => {
  customerFunctions()

  // datatable init
  let buukkaajaDatatable = $('#buukkaaja-datatable').DataTable({
    "language": {
      url: "/datatable_i18n"
    },
    "pageLength": 25,
    "processing": true,
    "responsive": true,
    "serverSide": true,
    "ajax": {
      "url": $('#buukkaaja-datatable').data('source')
    },
    "columns": [
      {"data": "company_name"},
      {"data": "location_name"},
      {"data": "business_id"},
      {"data": "address"},
      {"data": "contact_name"},
      {"data": "contact_phone"},
      {"data": "event_date"}
    ],
    "order": [[6, 'asc']],
    initComplete(settings, json) {
      // custom search bar
      $("#buukkaaja-datatable_filter").append('<div class="input-group floating form-group"><div class="input-group-prepend"><span class="input-group-text"><i class="bi bi-search"></i></span></div><div class="form-floating"></div></div>')
      $("#buukkaaja-datatable_filter input").removeClass('form-control-sm')
      $("#buukkaaja-datatable_filter input").detach().appendTo('#buukkaaja-datatable_filter .input-group.form-group .form-floating')
      $("#buukkaaja-datatable_filter label").remove()
      $("#buukkaaja-datatable_filter input").addClass('w-100 ms-0')
      $('<label>Hae</label>').insertAfter($("#buukkaaja-datatable_filter input"))
    }
  })
}
